/* eslint-disable import/prefer-default-export */
import dateFormat from 'dateformat';
import moment from 'moment';
import React from 'react';
import { userJobProfessionFilterOpts, userJobStatusFilterOpts } from '../../constants';
import Styles from '../../themes/style.module.scss';
import IconSelectFilter from './filters/IconSelectFilter';
import history from '../../history';
import { UserProfession, UserSubProfession } from '../../enums/UserProfession';

const style = {
  tableText: {
    fontWeight: '600',
    fontSize: '12px',
    fontFamily: 'Nunito',
    color: '#435963',
  },
};
const addressFormatter = (column) => {
  if (!column || !column?.city || !column?.state) {
    return <span>N/A</span>;
  }

  return <span style={{ fontSize: 12 }}>{`${column.city}, ${column.state}`}</span>;
};
const dateFormatter = (column) => {
  if (!column) {
    return <span>N/A</span>;
  }
  return <span>{dateFormat(new Date(column), 'ddd, mmmm dS, yyyy')}</span>;
};

const dateFormatterCancellationTime = (column) => {
  if (!column) {
    return <p>N/A</p>;
  }
  return <p> {dateFormat(column, 'dddd, mmmm dS, yyyy, h:MM:ss TT')}</p>;
};

const dateFormatterExpirationTime = (column) => {
  if (!column) {
    return <p>N/A</p>;
  }

  const duration = moment.duration(column);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  let formattedDuration = '';
  if (hours > 0) {
    formattedDuration += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }
  if (hours > 0 && minutes > 0) {
    formattedDuration += ' ';
  }
  if (minutes > 0) {
    formattedDuration += `${minutes} min`;
  }

  return <p>{formattedDuration}</p>;
};

const deviceFormatter = (column, colIndex) => {
  if (column === 'ios') {
    return <img src={require('../../images/ios.png')} alt="ios" style={{ width: '20px' }} />;
  }

  if (column === 'android') {
    return (
      <img src={require('../../images/android.png')} alt="android" style={{ width: '20px' }} />
    );
  }

  return <img src={require('../../images/desktop.png')} alt="desktop" style={{ width: '20px' }} />;
};

const statusFormatter = (openJob) => (column, jobData) =>
  (
    <div>
      <p style={{ textTransform: 'capitalize' }}>{column ?? 'N/A'}</p>
      <button
        type="button"
        style={{
          fontSize: 12,
          cursor: 'pointer',
          textDecoration: 'underline',
          appearance: 'none',
          background: 'none',
          border: 'none',
        }}
        onClick={() => openJob(jobData)}
      >
        View Job
      </button>
    </div>
  );
const viewJobFormatter = (openJob) => (column, jobData) =>
  (
    <div>
      <p
        style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => openJob(jobData)}
      >
        View Job
      </p>
    </div>
  );

const fullNameFormatter = (openUser) => (hygienist) => {
  if (!hygienist) {
    return <p>N/A</p>;
  }
  return (
    <p
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => openUser(hygienist._id, 'HYGIENIST')}
    >
      {`${hygienist.first_name} ${hygienist.last_name}`}
    </p>
  );
};

const fullNameForReferralFormatter = (openUser) => (user) => {
  const name = user?.full_name || 'N/A';
  return (
    <p
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => openUser(user.id, user?.user_type === 'HYG' ? 'HYGIENIST' : 'DENTIST')}
    >
      {name}
    </p>
  );
};

const getNameFormatter = (openUser) => (_, account) =>
  (
    <p
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => openUser(account.id, 'HYGIENIST')}
    >
      {`${account.first_name} ${account.last_name}`}
    </p>
  );

const getNameLinkFormatted = (getUrl) => (account) =>
  (
    <a
      href={getUrl(account.id, 'HYGIENIST')}
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline', ...style.tableText }}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        history.push(getUrl(account.id, 'HYGIENIST'));
      }}
    >
      {`${account.first_name} ${account.last_name}`}
    </a>
  );

const getOfficeLinkFormatted = (getOffice) => (id, name) =>
  (
    <a
      href={getOffice(id, 'DENTIST')}
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline', ...style.tableText }}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        history.push(getOffice(id, 'DENTIST'));
      }}
    >
      {name}
    </a>
  );

const getViewJobLinkFormatted = (getUrl) => (jobData, status) =>
  (
    <div
      style={{
        display: 'flex',
        gap: 4,
        flexDirection: 'column',
      }}
    >
      {status && (
        <span
          style={{
            textTransform: 'capitalize',
          }}
        >
          {status}
        </span>
      )}
      <a
        href={getUrl(jobData)}
        style={{
          fontSize: 12,
          cursor: 'pointer',
          textDecoration: 'underline',
          textAlign: 'center',
          ...style.tableText,
        }}
        rel="noreferrer"
        onClick={(event) => {
          event.preventDefault();
          history.push(getUrl(jobData));
        }}
      >
        View Job
      </a>
    </div>
  );

const officeNameFormatter = (openUser) => (column, jobData) =>
  (
    <p
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => openUser(jobData.offer_owner.id, 'DENTIST')}
    >
      {column}
    </p>
  );

const officeFormatter = (openUser) => (column, jobData) =>
  (
    <p
      style={{ fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => openUser(jobData.office.id, 'DENTIST')}
    >
      {column}
    </p>
  );

export const userJobListColumns = ({ getJobUrl, getUserUrl, sortable = true }) => [
  {
    selector: (row) => row.status,
    name: 'Status',
    format: (row) => getViewJobLinkFormatted(getJobUrl)(row, row.status),
    style: style.tableText,
  },
  {
    selector: (row) => row.subProfession ?? row.profession,
    name: 'Profession',
    style: style.tableText,
  },
  {
    selector: (row) => row.job_datetime,
    name: 'Shift Date',
    cell: (row) => dateFormatter(row.job_datetime),
    sortable,
    sortField: 'job_datetime',
    style: style.tableText,
  },
  {
    selector: (row) => row.local_start_time,
    name: 'Start',
    style: style.tableText,
  },
  {
    selector: (row) => row.local_end_time,
    name: 'End',
    style: style.tableText,
  },
  {
    selector: (row) => row.rate,
    name: 'Rate',
    format: (row) => `$ ${row.rate?.toFixed(2) || 0.0}`,
    sortable,
    sortField: 'rate',
    style: style.tableText,
  },
  {
    selector: (row) => row.offer_owner?.office_name,
    name: 'Office',
    cell: (row) =>
      getOfficeLinkFormatted(getUserUrl)(row.offer_owner?.id, row.offer_owner?.office_name),
    style: style.tableText,
  },
  {
    selector: (row) => row.offer_final_reciever,
    name: 'Hygienist',
    cell: (row) =>
      row.offer_final_reciever ? getNameLinkFormatted(getUserUrl)(row.offer_final_reciever) : 'N/A',
    style: style.tableText,
  },
  {
    selector: (row) => row.workingHours,
    name: 'Hours',
    format: (row) => `${row?.workingHours?.toFixed(2) || ''}`,
    style: style.tableText,
  },
  {
    selector: (row) => row.createdAt,
    name: 'Created',
    cell: (row) => dateFormatter(row.createdAt),
    sortable,
    sortField: 'createdAt',
    style: style.tableText,
  },
  {
    selector: (row) => row.offer_owner?.address?.[0],
    cell: (row) =>
      row.offer_owner?.address?.[0] ? addressFormatter(row.offer_owner.address[0]) : '-',
    name: 'City',
    style: style.tableText,
  },
  {
    selector: (row) => row.job_filled_datetime,
    name: 'Fill Date',
    cell: (row) => dateFormatter(row.job_filled_datetime),
    sortable,
    sortField: 'job_filled_datetime',
    style: style.tableText,
  },
];

export const customColumnOffers = ({ getJobUrl }) => [
  {
    selector: (row) => row.job_status,
    name: 'Status',
    format: (row) => getViewJobLinkFormatted(getJobUrl)(row, row.job_status),
    style: style.tableText,
  },
  {
    selector: (row) => row.subProfession ?? row.profession,
    name: 'Profession',
    format: (row) => row.subProfession ?? row.profession,
    style: style.tableText,
  },
  {
    selector: (row) => row.job_datetime,
    name: 'Shift Date',
    format: (row) => dateFormatter(row.job_datetime),
    sortable: true,
    sortField: 'job_datetime',
    style: style.tableText,
  },
  {
    selector: (row) => row.local_start_time,
    name: 'Start',
    format: (row) => row.local_start_time,
    style: style.tableText,
  },
  {
    selector: (row) => row.local_end_time,
    name: 'End',
    format: (row) => row.local_end_time,
    style: style.tableText,
  },
  {
    selector: (row) => row.rate,
    name: 'Rate',
    format: (row) => `$ ${row.rate?.toFixed(2) || 0.0}`,
    sortable: true,
    sortField: 'rate',
    style: style.tableText,
  },
  {
    selector: (row) => row.workingHourls,
    name: 'Hours',
    format: (row) => `${row?.workingHourls?.toFixed(2) || ''}`,
    style: style.tableText,
  },
  {
    selector: (row) => row.created_at,
    name: 'Created',
    cell: (row) => dateFormatter(row.created_at),
    sortable: true,
    sortField: 'created_at',
    style: style.tableText,
  },
  {
    selector: (row) => row.offer_owner?.address?.[0],
    format: (row) => addressFormatter(row.offer_owner?.address?.[0]),
    name: 'City',
    style: style.tableText,
  },
];

export const customUserColumns = ({ openJob, openUser }) => [
  {
    dataField: 'status',
    text: 'Status',
    formatter: statusFormatter(openJob),
    style: style.tableText,
  },
  {
    dataField: 'job_datetime',
    text: 'Shift Date',
    formatter: dateFormatter,
    sort: true,
    style: style.tableText,
  },
  {
    dataField: 'local_start_time',
    text: 'Start',
    style: style.tableText,
  },
  {
    dataField: 'local_end_time',
    text: 'End',
    style: style.tableText,
  },
  {
    dataField: 'rate',
    text: 'Final Rate',
    formatter: (val) => `$ ${val?.toFixed(2) || 0.0}`,
    sort: true,
    style: style.tableText,
  },
  {
    dataField: 'offer_final_reciever',
    text: 'Profession',
    formatter: fullNameFormatter(openUser),
    style: style.tableText,
  },
  {
    dataField: 'workingHours',
    text: 'Hours',
    formatter: (val) => `${val?.toFixed(2) || ''}`,
    style: style.tableText,
  },
  {
    dataField: 'createdAt',
    text: 'Created',
    formatter: dateFormatter,
    sort: true,
    style: style.tableText,
  },
];

export const customColumnPPJobs = ({ getJobUrl }) => [
  {
    selector: (row) => row.status,
    name: 'Status',
    format: (row) => getViewJobLinkFormatted(getJobUrl)(row, row.status),
    style: style.tableText,
  },
  {
    selector: (row) => row.start_date,
    name: 'Start Time',
    style: style.tableText,
  },
  {
    selector: (row) => row.rate,
    name: 'Final Rate',
    sortable: true,
    sortField: 'rate',
    style: style.tableText,
  },
  {
    selector: (row) => row.createdAt,
    name: 'Created',
    cell: (row) => dateFormatter(row.createdAt),
    sortable: true,
    sortField: 'createdAt',
    style: style.tableText,
  },
];

export const referralColumns = ({ getUserUrl, ActionComponent }) => [
  {
    selector: (row) => row.sender,
    name: 'Sender',
    format: (row) =>
      row?.sender?.user_type === 'HYG'
        ? getNameLinkFormatted(getUserUrl)(row.sender)
        : getOfficeLinkFormatted(getUserUrl)(row.sender?.id, row.sender?.office_name),
    style: style.tableText,
  },
  {
    selector: (row) => row.sender?.user_type,
    name: 'Sender Type',
    style: style.tableText,
  },
  {
    selector: (row) => row.sender?.amount,
    name: 'Sender Amount',
    style: style.tableText,
  },
  {
    selector: (row) => row.receiver,
    name: 'Receiver',
    format: (row) =>
      row.receiver?.user_type === 'HYG'
        ? getNameLinkFormatted(getUserUrl)(row.receiver)
        : getOfficeLinkFormatted(getUserUrl)(row.receiver?.id, row.receiver?.office_name),
    style: style.tableText,
  },
  {
    selector: (row) => row.receiver?.user_type,
    name: 'Receiver Type',
    style: style.tableText,
  },
  {
    selector: (row) => row.receiver?.amount,
    name: 'Receiver Amount',
    style: style.tableText,
  },
  {
    selector: (row) => row.receiver?.created_at,
    name: 'Signed Up Date',
    cell: (row) => dateFormatter(row.receiver?.created_at),
    style: style.tableText,
  },
  {
    name: '',
    cell: ActionComponent,
    style: style.tableText,
  },
];

export const licensesColumns = ({ getUserUrl }) => [
  {
    name: 'Name',
    selector: (row) => row.first_name,
    cell: (row) => getNameLinkFormatted(getUserUrl)(row),
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses?.credentials?.[0]?.entry_field?.license,
    name: 'License #',
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses.subProfession ?? row.licenses.profession,
    name: 'Profession',
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses.state,
    name: 'State',
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses.addedAt,
    name: 'Created At',
    format: (row) => dateFormatter(row.licenses.addedAt),
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses.expirationDate,
    name: 'Expiration Year',
    format: (row) => {
      if (!row.licenses.expirationDate) {
        return 'N/A';
      }
      return moment(row.licenses?.expirationDate).format('YYYY');
    },
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses?.status,
    name: 'Status',
    style: style.tableText,
  },
  {
    selector: (row) => row.licenses?.shiftsCompleted,
    name: 'Shifts Completed',
    format: (row) => {
      if (!row.licenses.shiftsCompleted) {
        return 'N/A';
      }
      return row.licenses.shiftsCompleted;
    },
    sortable: true,
    sortField: 'licenses.shiftsCompleted',
    style: style.tableText,
  },
];

export const permanentJobsColumn = ({ getJobUrl, getOfficeUrl }) => [
  {
    name: 'Status',
    selector: (row) => row.status,
    format: (row) => getViewJobLinkFormatted(getJobUrl)(row, row.status),
    style: style.tableText,
  },
  {
    name: 'Start Date',
    selector: (row) => row.start_date,
    sortable: true,
    sortField: 'start_date',
    style: style.tableText,
  },
  {
    name: 'Position',
    selector: (row) => row.position,
    style: style.tableText,
  },
  {
    name: 'Rate',
    selector: (row) => row.rate,
    sortable: true,
    sortField: 'rate',
    style: style.tableText,
  },
  {
    name: 'Office',
    selector: (row) => row.office?.office_name,
    cell: (row) => getOfficeLinkFormatted(getOfficeUrl)(row.office.id, row.office.office_name),
    style: style.tableText,
  },
  {
    name: 'Recruiter',
    selector: (row) => row.recruiter,
    style: style.tableText,
  },
  {
    name: 'Created',
    selector: (row) => row.createdAt,
    format: (row) => dateFormatter(row.createdAt),
    style: style.tableText,
    sortable: true,
    sortField: 'createdAt',
  },
];

export const multidayColumn = ({ getJobUrl, getOfficeUrl }) => [
  {
    name: 'Multiday',
    selector: (row) => row.id,
    format: (row) => getViewJobLinkFormatted(getJobUrl)(row),
    style: style.tableText,
  },
  {
    name: 'Profession Type',
    selector: (row) => row.jobs?.[0]?.subProfession ?? row.profession,
    format: (row) => row.jobs?.[0]?.subProfession ?? row.profession ?? 'RDH',
    style: style.tableText,
  },
  {
    name: 'Created',
    selector: (row) => row.createdAt,
    format: (row) => dateFormatter(row.createdAt),
    style: style.tableText,
  },
  {
    name: 'First Shift',
    selector: (row) => row.firstShiftDate,
    format: (row) => dateFormatter(row.firstShiftDate),
    style: style.tableText,
  },
  {
    name: 'Last Shift',
    selector: (row) => row.lastShiftDate,
    format: (row) => dateFormatter(row.lastShiftDate),
    style: style.tableText,
  },
  {
    name: 'Office',
    selector: (row) => row.office?.office_name,
    cell: (row) => getOfficeLinkFormatted(getOfficeUrl)(row.office?.id, row.office?.office_name),
    style: style.tableText,
  },
  {
    name: 'Worked Days',
    selector: (row) => row.jobs,
    format: (row) => row?.jobs.length,
    style: style.tableText,
  },
  {
    name: 'City',
    selector: (row) => row.office?.address?.[0],
    format: (row) => addressFormatter(row.office?.address?.[0]),
    style: style.tableText,
  },
];

export const multidayJobsColumn = ({ openJob, openUser }) => [
  {
    dataField: 'status',
    text: 'Status',
    formatter: statusFormatter(openJob),
    style: style.tableText,
  },
  {
    text: 'Shift Date',
    dataField: 'job_datetime',
    formatter: dateFormatter,
    sort: true,
    style: style.tableText,
  },
  {
    dataField: 'local_start_time',
    text: 'Start',
    style: style.tableText,
  },
  {
    dataField: 'local_end_time',
    text: 'End',
    style: style.tableText,
  },
  {
    dataField: 'office_name',
    text: 'Office',
    formatter: officeFormatter(openUser),
    style: style.tableText,
  },
  {
    dataField: 'rate',
    text: 'Rate',
    sort: true,
    style: style.tableText,
  },
];

export const referralRowColorStyles = [
  {
    when: (row) => row.status === 'unfinished',
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
  {
    when: (row) => row.status === 'finished',
    style: {
      backgroundColor: '#C1F5B9',
    },
  },
  {
    when: (row) => row.status === 'completed',
    style: {
      backgroundColor: '#C0E9F2',
    },
  },
];

export const rowColorCodeClass = (row) => {
  const { job_status: status, is_cancelled: isCancelled } = row || {};
  const jobStatus = status?.toLowerCase();

  if (isCancelled || jobStatus === 'expired' || jobStatus === 'cancelled') {
    return Styles.redRow;
  }

  if (jobStatus === 'open' || jobStatus === 'pending' || jobStatus === 'counter') {
    return Styles.yellowRow;
  }

  if (jobStatus === 'N/F') {
    return Styles.orangeRow;
  }

  if (jobStatus === 'filled' || jobStatus === 'pay-out') {
    return Styles.greenRow;
  }

  if (jobStatus === 'complete') {
    return Styles.blueRow;
  }

  return null;
};

export const jobsRowColorCodeClass = (row) => {
  const { status, is_cancelled: isCancelled } = row || {};
  const jobStatus = status?.toLowerCase();

  if (isCancelled || jobStatus === 'expired' || jobStatus === 'cancelled') {
    return Styles.redRow;
  }

  if (jobStatus === 'open' || jobStatus === 'pending' || jobStatus === 'counter') {
    return Styles.yellowRow;
  }

  if (jobStatus === 'N/F') {
    return Styles.orangeRow;
  }

  if (jobStatus === 'filled' || jobStatus === 'pay-out') {
    return Styles.greenRow;
  }

  if (jobStatus === 'complete') {
    return Styles.blueRow;
  }

  return null;
};

export const referralRowClasses = (row) => {
  let classes = null;
  if (row.status === 'unfinished') {
    classes = Styles.yellowRow;
  } else if (row.status === 'finished') {
    classes = Styles.greenRow;
  } else if (row.status === 'completed') {
    classes = Styles.blueRow;
  }
  return classes;
};

export const licenseRowClasses = (row) => {
  let classes = null;
  if (row.licenses.status === 'blocked' || row.licenses.status === 'invalid') {
    classes = Styles.redRow;
  } else if (row.licenses.status === 'new') {
    classes = Styles.yellowRow;
  } else if (row.licenses.status === 'expired') {
    classes = Styles.orangeRow;
  } else if (row.licenses.status === 'verified') {
    classes = Styles.greenRow;
  } else if (row.licenses.status === 'upcoming') {
    classes = Styles.pinkRow;
  }
  return classes;
};

export const ppRowColors = (row) => {
  let classes = null;
  if (row.status?.toLowerCase() === 'closed') {
    classes = Styles.redRow;
  } else if (row.status === 'draft') {
    classes = Styles.yellowRow;
  } else if (row.status?.toLowerCase() === 'active') {
    classes = Styles.greenRow;
  }
  return classes;
};

export const multidayJobConditionalRowStyles = [
  {
    when: (row) => row.profession === UserProfession.DA,
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
  {
    when: (row) => row.profession === UserProfession.DN,
    style: {
      backgroundColor: '#C0E9F2',
    },
  },
  {
    when: (row) => row.profession !== UserProfession.DN && row.profession !== UserProfession.DA,
    style: {
      backgroundColor: '#C1F5B9',
    },
  },
];

export const ppJobsConditionalRowStyles = [
  {
    when: (row) => row.status?.toLowerCase() === 'closed',
    style: {
      backgroundColor: '#F8B5B5',
    },
  },
  {
    when: (row) => row.status === 'draft',
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
  {
    when: (row) => row.status?.toLowerCase() === 'active',
    style: {
      backgroundColor: '#C1F5B9',
    },
  },
];

const getJobListRowStyle = (row) => {
  const { job_status, status, is_cancelled: isCancelled } = row || {};
  const jobStatus = (status || job_status)?.toLowerCase();

  if (isCancelled || jobStatus === 'expired' || jobStatus === 'cancelled') {
    return { backgroundColor: '#F8B5B5' };
  }
  if (jobStatus === 'open' || jobStatus === 'pending' || jobStatus === 'counter') {
    return { backgroundColor: '#FDFFC9' };
  }
  if (jobStatus === 'n/f') {
    return { backgroundColor: '#FFDB9A' };
  }
  if (jobStatus === 'filled' || jobStatus === 'pay-out') {
    return { backgroundColor: '#C1F5B9' };
  }
  if (jobStatus === 'complete') {
    return { backgroundColor: '#C0E9F2' };
  }
  return null;
};

export const jobListConditionalRowStyles = [
  {
    when: (row) => !!getJobListRowStyle(row),
    style: (row) => getJobListRowStyle(row),
  },
];

export const licensesConditionalRowStyles = [
  {
    when: (row) => row.licenses.status === 'blocked' || row.licenses.status === 'invalid',
    style: {
      backgroundColor: '#F8B5B5',
    },
  },
  {
    when: (row) => row.licenses.status === 'new',
    style: {
      backgroundColor: '#FDFFC9',
    },
  },
  {
    when: (row) => row.licenses.status === 'expired',
    style: {
      backgroundColor: '#FFDB9A',
    },
  },
  {
    when: (row) => row.licenses.status === 'verified',
    style: {
      backgroundColor: '#C1F5B9',
    },
  },
  {
    when: (row) => row.licenses.status === 'upcoming',
    style: {
      backgroundColor: '#F3C9EC',
    },
  },
];

export const jobListColumn = ({
  getUserUrl,
  getJobUrl,
  showCounterExpiration = false,
  cancellationTime = false,
}) => [
  {
    selector: (row) => row.status,
    name: 'Status',
    format: (row) => getViewJobLinkFormatted(getJobUrl)(row, row.status),
    style: style.tableText,
  },
  {
    selector: (row) => row.subProfession ?? row.profession,
    name: 'Profession',
    style: style.tableText,
  },
  {
    selector: (row) => row.job_datetime,
    name: 'Shift Date',
    cell: (row) => dateFormatter(row.job_datetime),
    sortable: true,
    sortField: 'job_datetime',
    style: style.tableText,
  },
  {
    selector: (row) => row.local_start_time,
    name: 'Start',
    style: style.tableText,
  },
  {
    selector: (row) => row.local_end_time,
    name: 'End',
    style: style.tableText,
  },
  {
    selector: (row) => row.counterExpirationTime,
    name: 'Time Until Expiration',
    cell: (row) => dateFormatterExpirationTime(row.counterExpirationTime),
    style: style.tableText,
    sortable: true,
    sortField: 'counterExpirationTime',
    omit: !showCounterExpiration,
  },
  {
    selector: (row) => row.rate,
    name: 'Rate',
    format: (row) => `$ ${row.rate?.toFixed(2) || 0.0}`,
    sortable: true,
    sortField: 'rate',
    style: style.tableText,
  },
  {
    selector: (row) => row.offer_owner?.office_name,
    name: 'Office',
    cell: (row) =>
      row.offer_owner
        ? getOfficeLinkFormatted(getUserUrl)(row.offer_owner.id, row.offer_owner.office_name)
        : '-',
    style: style.tableText,
  },
  {
    selector: (row) => row.offer_final_reciever,
    name: 'Hygienist',
    cell: (row) =>
      row.offer_final_reciever ? getNameLinkFormatted(getUserUrl)(row.offer_final_reciever) : 'N/A',
    style: style.tableText,
  },
  {
    selector: (row) => row.workingHours,
    name: 'Hours',
    format: (row) => `${row?.workingHours.toFixed(2) || ''}`,
    style: style.tableText,
  },
  {
    name: 'Created',
    selector: (row) => row.createdAt,
    cell: (row) => dateFormatter(row.createdAt),
    style: style.tableText,
    sortable: true,
    sortField: 'createdAt',
  },
  {
    selector: (row) => row.offer_owner?.address?.[0],
    format: (row) =>
      row.offer_owner?.address?.[0] ? addressFormatter(row.offer_owner.address[0]) : '-',
    name: 'City',
    style: style.tableText,
  },
  {
    selector: (row) => row.job_filled_datetime,
    name: 'Fill Date',
    cell: (row) => dateFormatter(row.job_filled_datetime),
    sortable: true,
    sortField: 'job_filled_datetime',
    style: style.tableText,
  },
  {
    selector: (row) => row.job_cancelled_datetime,
    name: 'Cancellation Time',
    cell: (row) => dateFormatterCancellationTime(row.job_cancelled_datetime),
    style: style.tableText,
    sortable: true,
    sortField: 'job_cancelled_datetime',
    omit: !cancellationTime,
  },
];
